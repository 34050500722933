import {
    handleQueryResolve
} from '../utils'

// PatientID, GenericName = '', Strength = '', extendedWhere = 'rx.RxDate > DATEADD(month, -6, GetDate())'
export default function (patientID) {
// FIXME - need to set this up to work with the query for the blister pack
  if (patientID) {
    const attrs = [{
      param: 'PatientID',
      type: 'sql.Int',
      value: patientID, // to support like
    }]
    const where = ['Pat.ID = @PatientID']

    return this.query(`
        SELECT
            lastname,
            firstname,
            SUM(brk) AS Brk,
            SUM(noon) AS Noon,
            SUM(supp) AS Supp,
            SUM(bed) AS Bed,
            din,
            BrandName,
            Strength,
            GenericName
        FROM (
            SELECT
                Pat.ID,
                Pat.LastName,
                pat.FirstName,
                CAST(Rx.FillDate as date) as filldateedit,
                iif (RxPassTime.Seq = '1',CAST(UnitDose AS DECIMAL(5, 2)),0) as Brk,
                iif (RxPassTime.Seq = '2',CAST(UnitDose AS DECIMAL(5, 2)),0) as Noon,
                iif (RxPassTime.Seq = '3',CAST(UnitDose AS DECIMAL(5, 2)),0) as Supp,
                iif (RxPassTime.Seq = '4',CAST(UnitDose AS DECIMAL(5, 2)),0) as Bed,
                RxPassTime.Seq,
                rx.DIN,
                drg.BrandName,
                drg.Strength,
                drg.GenericName
            FROM Pharmacy.dbo.RxPassTime
            INNER JOIN Pharmacy.dbo.Rx ON Pharmacy.dbo.RxPassTime.RxNum = Pharmacy.dbo.Rx.RxNum
            INNER JOIN Pharmacy.dbo.Pat ON Pharmacy.dbo.Rx.PatID = Pharmacy.dbo.Pat.ID
            INNER JOIN Pharmacy.dbo.Drg ON Pharmacy.dbo.Rx.DIN = Pharmacy.dbo.Drg.DIN
            WHERE ${where}
            AND CAST(Rx.FillDate as date) in (
                SELECT TOP 1 filldateedit FROM (
                    SELECT Pat.ID, CAST(Rx.FillDate AS date) as filldateedit
                    FROM [Pharmacy].[dbo].[RxPassTime]
                    INNER JOIN Pharmacy.dbo.Rx ON Pharmacy.dbo.RxPassTime.RxNum = Pharmacy.dbo.Rx.RxNum
                    INNER JOIN Pharmacy.dbo.Pat ON Pharmacy.dbo.Rx.PatID = Pharmacy.dbo.Pat.ID
                    INNER JOIN Pharmacy.dbo.Drg ON Pharmacy.dbo.Rx.DIN = Pharmacy.dbo.Drg.DIN
                    WHERE ${where}
                ) AS FilldateByPat
                GROUP BY filldateedit
                ORDER BY filldateedit DESC
            )
        ) AS blisterpackdata
        GROUP BY lastname, firstname, din, filldateedit, BrandName, GenericName, Strength;
        `, attrs).then(handleQueryResolve)
  }
}

// import {
//     handleQueryResolve
// } from '../utils'

// export default function (PatientID) {
//   let where
//   let attrs
//   if (Array.isArray(PatientID)) {
//     // FIXME Filter the PatientIDs
//     where = [`Pat.ID IN (${PatientID.join(',')})`]
//   } else {
//     attrs = [{
//       param: 'PatientID',
//       type: 'sql.Int',
//       value: PatientID, // to support like
//     }]
//     where = ['Pat.ID = @PatientID']
//   }

//   const query = `
//         SELECT
//             lastname,
//             firstname,
//             SUM(brk) AS Brk,
//             SUM(noon) AS Noon,
//             SUM(supp) AS Supp,
//             SUM(bed) AS Bed,
//             din,
//             BrandName,
//             Strength,
//             GenericName
//         FROM (
//             SELECT
//                 Pat.ID,
//                 Pat.LastName,
//                 pat.FirstName,
//                 CAST(Rx.FillDate as date) as filldateedit,
//                 iif (RxPassTime.Seq = '1',CAST(UnitDose AS DECIMAL(5, 2)),0) as Brk,
//                 iif (RxPassTime.Seq = '2',CAST(UnitDose AS DECIMAL(5, 2)),0) as Noon,
//                 iif (RxPassTime.Seq = '3',CAST(UnitDose AS DECIMAL(5, 2)),0) as Supp,
//                 iif (RxPassTime.Seq = '4',CAST(UnitDose AS DECIMAL(5, 2)),0) as Bed,
//                 RxPassTime.Seq,
//                 rx.DIN,
//                 drg.BrandName,
//                 drg.Strength,
//                 drg.GenericName
//             FROM Pharmacy.dbo.RxPassTime
//             INNER JOIN Pharmacy.dbo.Rx ON Pharmacy.dbo.RxPassTime.RxNum = Pharmacy.dbo.Rx.RxNum
//             INNER JOIN Pharmacy.dbo.Pat ON Pharmacy.dbo.Rx.PatID = Pharmacy.dbo.Pat.ID
//             INNER JOIN Pharmacy.dbo.Drg ON Pharmacy.dbo.Rx.DIN = Pharmacy.dbo.Drg.DIN
//             WHERE ${where}
//             AND CAST(Rx.FillDate as date) in (
//                 SELECT TOP 1 filldateedit FROM (
//                     SELECT Pat.ID, CAST(Rx.FillDate AS date) as filldateedit
//                     FROM [Pharmacy].[dbo].[RxPassTime]
//                     INNER JOIN Pharmacy.dbo.Rx ON Pharmacy.dbo.RxPassTime.RxNum = Pharmacy.dbo.Rx.RxNum
//                     INNER JOIN Pharmacy.dbo.Pat ON Pharmacy.dbo.Rx.PatID = Pharmacy.dbo.Pat.ID
//                     INNER JOIN Pharmacy.dbo.Drg ON Pharmacy.dbo.Rx.DIN = Pharmacy.dbo.Drg.DIN
//                     WHERE ${where}
//                 ) AS FilldateByPat
//                 GROUP BY filldateedit
//                 ORDER BY filldateedit DESC
//             )
//         ) AS blisterpackdata
//         GROUP BY lastname, firstname, din, filldateedit, BrandName, GenericName, Strength;
//         `

//   console.log(query)

//   return this.query(query, attrs).then(handleQueryResolve)
// }
